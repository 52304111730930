/* eslint camelcase: 0 */

import { formatDate } from '../../utils';

import experienceLogo from '../../../img/experience.png';
import googleLogo from '../../../svg/google.svg';
import zillowLogo from '../../../svg/zillow.svg';
import quote from '../../../svg/quote.svg';

const vendorLogo = {
  experience: experienceLogo,
  zillow: zillowLogo,
  google: googleLogo,
};

export const htmlTemplateReview = (review, state) => {
  const { date, first_name, last_name, content, service, total } = review;
  const firstName = first_name;
  const lastName = last_name ?? ' ';
  const name = `${firstName} ${lastName[0] ?? ''}.`;
  const draftState = state;

  if (!draftState.total) {
    draftState.total = total;
  }

  return `<div class="testimonials_slide w-slide">
            <div class="testimonial_slide_inner">
              <div class="testimonial_image_wrap">
                  <div class="testimonial_image_box">
                    <img src="${vendorLogo[service]}"
                      loading="lazy"
                      width="80"
                      alt="company logo"
                      class="testimonial-image">
                    <img src="${quote}"
                      loading="lazy"
                      alt="quote"
                      class="reviews-slide__quote-img">
                  </div>
                </div>

                <div class="testimonial__main-block">
                  <div class="testimonial__name-row">
                    <div class="h3__title-text">${name}</div>
                    <div class="text-m _140-06-text"></div>
                  </div>
                  <div class="text-m">${content}</div>
                </div>

                <div class="testimonial__info-block">
                  <div class="testimonial__info-box">
                    <div class="text-m _140-06-text">Recommendation</div>
                    <div class="h3__title-text _09-text">${formatDate(date)}</div>
                  </div>
                  <div class="testimonial__info-box">
                    <div class="text-m _140-06-text"></div>
                    <div class="h3__title-text _09-text"></div>
                  </div>
                </div>
            </div>
        </div>`;
};
