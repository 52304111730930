import { htmlTemplateReview } from './htmlTemplateReview';
import { htmlTemplateReviewPage } from './htmlTampleReviewsPage';

const htmlReviews = (elements, reviews, state) => {
  const { links } = elements;

  return reviews.map((review) => htmlTemplateReview({ ...review, links }, state)).join('');
};

const htmlReviewsPage = (elements, reviews, state) => {
  const { links } = elements;

  return reviews.map((review) => htmlTemplateReviewPage({ ...review, links }, state)).join('');
};

const renderTotal = (elements, state) => {
  const { total: totalEl } = elements;
  const { total } = state;

  if (totalEl && total) {
    totalEl.textContent = total;
  }
};

export const renderReviews = (elements, reviews, state) => {
  const { wrapperReviews, wrapperReviewsPage } = elements;

  if (wrapperReviewsPage) {
    wrapperReviewsPage.innerHTML = '';
    wrapperReviewsPage.insertAdjacentHTML('afterbegin', htmlReviewsPage(elements, reviews, state, 'page'));
  }

  if (wrapperReviews) {
    wrapperReviews.innerHTML = '';
    wrapperReviews.insertAdjacentHTML('afterbegin', htmlReviews(elements, reviews, state, 'main'));
    renderTotal(elements, state);
  }
};
