/* eslint no-unused-expressions: 0 */
export const webflowRestart = () => {
  window.Webflow && window.Webflow.destroy();
  window.Webflow && window.Webflow.ready();
  window.Webflow && window.Webflow.require('ix2').init();
  document.dispatchEvent(new Event('readystatechange'));
};

export const dateFormatted = (dateString) => {
  const newDate = new Date(dateString);
  const dateToDDMMYY = newDate.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

  return dateToDDMMYY;
};
